<template>
	<van-popup v-model="visible" round closeable position="bottom" get-container="body" :style="{ height: '80%' }">
		<div class="main-wrap-popup">
			<div class="main-header"><div class="title">借用详情</div></div>
			<div class="main-container layout-1">
				<div class="row-1">
					<div class="text-1-wrap">
						<div class="icon-wrap"><van-icon name="clock-o" /></div>
						<div class="text-1">{{ formatStatusLabel(orderData.orderStatus) }}</div>
					</div>
					<div class="text-2">{{ formatStatusSubLabel(orderData.orderStatus) }}</div>
				</div>

				<div class="row-2">
					<div class="row-2-1">
						<span class="text-1">借用人：{{ orderData.orderUser }}</span>
						<!-- <span class="text-2">13412347777</span> -->
					</div>
					<div class="row-2-2">科室：{{ orderData.tenantName }}</div>
				</div>
				<div class="row-3">
					<div class="title">{{ orderData.name }}</div>
					<div class="row-wrap">
						<div class="left-img"><img class="img" :src="$replacePhoto(imgUrl)" alt="" /></div>
						<div class="right-list">
							<div class="list-item">
								<span class="label">{{$VUEAPPDEVICECODE()}}</span>
								<span class="value">{{ orderData.code }}</span>
							</div>
							<div class="list-item">
								<span class="label">设备分类</span>
								<span class="value">{{ orderData.categoryName }}</span>
							</div>
							<div class="list-item">
								<span class="label">品牌</span>
								<span class="value">{{ orderData.brandName }}</span>
							</div>
							<div class="list-item">
								<span class="label">型号</span>
								<span class="value">{{ orderData.modelName }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row-4">
					<div class="list-item">
						<span class="label">计费方式</span>
						<span class="value">{{ $formatBillingType1(orderData.billingType).name }}</span>
					</div>
					<div class="list-item">
						<span class="label">预计费用</span>
						<span class="value">{{ orderData.amount || '-' }}元</span>
					</div>
					<div class="list-item">
						<span class="label">配送方式</span>
						<span class="value">自提</span>
					</div>
				</div>
				<div class="row-4">
					<div class="list-item">
						<span class="label">订单编号</span>
						<span class="value">{{ orderData.orderId }}</span>
					</div>
					<div class="list-item">
						<span class="label">卡片号</span>
						<span class="value">{{ orderData.label }}</span>
					</div>
					<div class="list-item">
						<span class="label">创建时间</span>
						<span class="value">{{ formatDateTime(orderData.orderTime) }}</span>
					</div>
					<div class="list-item">
						<span class="label">扫码时间</span>
						<span class="value">{{ formatDateTime(orderData.startTime) }}</span>
					</div>
				</div>
			</div>
		</div>
	</van-popup>
</template>

<script>
import { formatStatusLabel, formatStatusSubLabel } from '@/baseConfig/orderStatusOptions/index.js';
export default {
	name: 'detailPopup',
	components: {},
	data() {
		return {
			formatStatusLabel: formatStatusLabel,
			formatStatusSubLabel: formatStatusSubLabel,
			orderData: {},
			id: '',
			visible: false
		};
	},
	computed: {
		imgUrl() {
			if (this.$valueIsExist(this.orderData, 'photo') && this.orderData['photo'] != '') {
				let photo = this.orderData['photo'];
				return `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${this.$getPhotosId(photo)}&scale=0.3`;
			}
			return this.$replacePhoto('');
		}
	},
	created() {},
	mounted() {},
	methods: {
		orderDetail() {
			this.$api.deviceBorrowModule
				.orderDetail({
					id: this.id
				})
				.then(res => {
					this.orderData = res.data;
				})
				.catch(e => {});
		},
		show(id) {
			this.visible = true;
			this.id = id;
			this.orderDetail();
		},
		hide() {
			this.visible = false;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
@import '@styles/layout.scss';
.main-wrap-popup {
	background: linear-gradient(360deg, rgba(114, 171, 253, 0) 0%, #568efc 70%, #3e73fb 100%);
	background-size: 100% 195px;
	background-repeat: no-repeat;
	.main-header {
		.title {
			color: #fff;
		}
	}
	.main-container {
		padding-top: 20px;
	}
}
</style>
